<template>
  <div>
    <el-form :disabled="isShow" size="small" :model="form">
      <div :class="tableRowKey" :style="`${needFixedHeight ? 'height:' + tableMaxHeight + 'px' : null};`">
        <el-table
          style="border-radius: 4px"
          ref="multiTable"
          v-bind="$attrs"
          v-on="$listeners"
          border
          :height="tableMaxHeight + 'px'"
          :stripe="stripe"
          :data="tableData"
          :row-key="tableRowKey"
          :key="tableKey"
          :header-cell-class-name="'headerCellClass'"
          :cell-style="cellStyle"
          :row-style="rowStyle"
          :row-class-name="rowClassName"
          :show-summary="showSummary"
          :summary-method="getSummaries"
          @select="tableSelect"
          @selection-change="tableSelectionChange"
          @select-all="tableSelectAll"
          @row-dblclick="dbClickJp"
          @row-click="rowClick"
          @sort-change="sortChange"
        >
          <template v-if="$slots.append" #append>
            <slot name="append"></slot>
          </template>
          <el-table-column v-if="needExpand" fixed="left" type="expand">
            <template v-slot="scope">
              <slot name="expand" v-bind="scope" />
            </template>
          </el-table-column>
          <!--
              :selectable="row => ($route.path.indexOf('list') !== -1 ? Number(row.stff_id) === Number($cookies.get('userInfo').stff_id) : selectable)"
          -->
          <template v-for="(item, index) in tableColumn">
            <el-table-column
              v-if="['selection', 'index'].includes(item.prop)"
              :key="`${tableRowKey}-${item.prop}-${index}-if`"
              v-bind="item"
              :width="item.labelWidth ? item.labelWidth : item.label ? item.label.length * 20 + 30 : 50"
              align="center"
              :selectable="selectable"
              :disabled="item.subItem ? (item.subItem.disabled ? item.subItem.disabled : false) : false"
              :column-key="`${tableRowKey}columnKey${index}`"
            >
              <template v-slot:header="scope">
                <span v-if="$scopedSlots[`header-${item.prop}`]">
                  <slot :name="`header-${item.prop}`" v-bind="scope" />
                </span>
                <span v-else>{{ scope.column.label }}</span>
              </template>
            </el-table-column>
            <el-table-column
              v-else-if="!item.invisible"
              :column-key="`${tableRowKey}columnKey${index}`"
              :key="`${tableRowKey}-${item.prop}-${index}`"
              v-bind="item"
              :sortable="item.sortable === true ? 'custom' : item.sortable === false ? false : item.sortable"
              :min-width="
                item.widthAuto
                  ? item.widthAuto
                  : item.labelWidth
                  ? item.labelWidth
                  : item[`sum_${item.prop}`] && item[`sum_${item.prop}`].toString().length > item.label.length * 2
                  ? item[`sum_${item.prop}`].toString().length * 10
                  : item.label.length * 1.3 * 20
              "
              :fixed="item.fixed ? item.fixed : false"
              :align="item.align ? item.align : 'center'"
              :show-overflow-tooltip="item.overflowTooltip ? item.overflowTooltip : true"
            >
              <template v-slot:header="scope">
                <div>
                  <div v-show="needSummation" :class="`summation ${item[`sum_${item.prop}`] ? 'summationBorderBottom' : ''}`">
                    {{ item[`sum_${item.prop}`] }}
                  </div>
                  <div v-if="!$scopedSlots[`header-${item.prop}`]">
                    <span :class="`${item.subItem ? (item.subItem.required ? 'vg_deep_red headerText' : 'headerText') : 'headerText'}`">{{
                      scope.column.label
                    }}</span>
                    <div @click="handleClick">
                      <div>
                        <slot :name="`header-${item.prop}`" v-bind="searchForm" />
                      </div>
                      <div v-if="needSearch">
                        <el-date-picker
                          v-if="item.itemType === 'date'"
                          v-model="searchForm[item.prop]"
                          show-word-limit
                          type="date"
                          size="mini"
                          :value-format="item.valueFormat"
                          clearable
                          :disabled="item.input === false"
                          :placeholder="item.input === false ? '' : '请选择'"
                          @change="getData"
                        />
                        <el-input
                          v-if="item.itemType === 'input'"
                          size="mini"
                          :disabled="item.input === false"
                          v-model="searchForm[item.prop]"
                          :placeholder="item.input === false ? '' : '请输入'"
                          clearable
                          @clear="getData"
                          @input="val => (item.searchInput ? item.searchInput(val, searchForm, item.prop) : '')"
                          @keyup.enter.native="getData(index)"
                        />
                        <el-select
                          v-if="item.itemType === 'select'"
                          :multiple="item.multiple"
                          size="mini"
                          :multiple-limit="item.multipleLimit"
                          :name="item.name"
                          :placeholder="item.input === false ? '' : '请选择'"
                          :allow-create="item.allowCreate"
                          :disabled="item.input === false"
                          :collapse-tags="item.collapseTags"
                          :filter-method="item.filterMethod"
                          filterable
                          :loading-text="item.loadingText"
                          :no-data-text="item.noDataText"
                          :popper-class="item.popperClass"
                          :no-match-text="item.noMatchText"
                          :loading="item.loading"
                          :remote="item.remote"
                          :remote-method="item.remoteMethod"
                          v-model="searchForm[item.prop]"
                          clearable
                          @change="() => (item.multiple ? () => {} : getData())"
                          @clear="getData(index)"
                          @visible-change="
                            val => (searchForm[item.prop] && searchForm[item.prop].length > 0 && item.multiple && !val ? getData(index) : () => {})
                          "
                          @remove-tag="val => headerRemoveTagChange(val, searchForm[item.prop])"
                        >
                          <el-option
                            v-for="(item, index) in item.options"
                            :key="`${tableRowKey}select${item.value}${index}`"
                            :label="item.label"
                            :value="item.value"
                            :disabled="item.disabled"
                            :style="selectOptionStyle"
                          ></el-option>
                        </el-select>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-slot="scope">
                <span v-if="$scopedSlots[item.prop]">
                  <slot :name="item.prop" v-bind="scope" />
                </span>
                <div v-else-if="item.subItem">
                  <el-form-item
                    label-width="0"
                    :prop="`${tableRowKey}.${scope.$index}.${item.prop}`"
                    :rules="{ required: item.subItem.required, message: ' ' }"
                  >
                    <div v-if="item.subItem.type === 'input'">
                      <el-input
                        v-if="computedData && computedData[item.prop]"
                        :value="computedData[item.prop](scope.$index)"
                        disabled
                        size="mini"
                        placeholder="自动"
                      />
                      <el-input
                        v-else
                        clearable
                        show-word-limit
                        size="mini"
                        :autosize="item.subItem.autosize"
                        :type="item.subItem.inputType"
                        v-model="scope.row[item.prop]"
                        :disabled="typeof item.subItem.disabled === 'function' ? item.subItem.disabled(scope.row) : item.subItem.disabled"
                        :placeholder="item.subItem.placeholder ? item.subItem.placeholder : '请输入'"
                        :maxlength="item.subItem.maxLength"
                        @input="event => (item.subItem.input ? item.subItem.input(event, scope.row) : () => {})"
                        @change="event => (item.subItem.change ? item.subItem.change(event, scope.row) : () => {})"
                        @blur="event => (item.subItem.blur ? item.subItem.blur(event, scope.row) : () => {})"
                      >
                        <template slot="append" v-if="$scopedSlots[`subItem-${item.prop}`]">
                          <slot :name="`subItem-${item.prop}`" v-bind="scope" />
                        </template>
                      </el-input>
                    </div>
                    <div v-if="item.subItem.type === 'image'">
                      <el-image
                        lazy
                        :key="Date.now()"
                        :scroll-container="`.${tableRowKey} .el-table__body-wrapper`"
                        style="height: 80px"
                        :preview-src-list="[typeof item.subItem.image === 'function' ? item.subItem.image(scope.row) : item.subItem.image]"
                        :src="typeof item.subItem.thumbnail === 'function' ? item.subItem.thumbnail(scope.row) : item.subItem.thumbnail"
                      >
                        <div slot="error" style="height: 100%" class="flexHV">
                          <span>暂无</span>
                        </div>
                      </el-image>
                    </div>
                    <div v-if="item.subItem.type === 'uploadImage'" style="display: flex">
                      <el-image
                        lazy
                        :scroll-container="`.${tableRowKey} .el-table__body-wrapper`"
                        style="height: 80px; width: 80px"
                        class="flexHV"
                        :preview-src-list="[typeof item.subItem.image === 'function' ? item.subItem.image(scope.row) : item.subItem.image]"
                        :src="typeof item.subItem.thumbnail === 'function' ? item.subItem.thumbnail(scope.row) : item.subItem.thumbnail"
                      >
                        <span slot="error" v-html="'暂无'"></span>
                      </el-image>
                      <el-upload
                        :ref="`uploadMultiple${scope.$index}`"
                        :action="uploadUrl"
                        :data="dataBody"
                        :show-file-list="false"
                        :on-success="res => handleAvatarSuccess(res, scope.row)"
                        :before-upload="beforeAvatarUpload"
                      >
                        <el-link :disabled="isShow" type="primary">点击上传</el-link>
                      </el-upload>
                      <div id="preview" @paste="e => handlePaste(e, scope.$index)" class="flexHV">
                        <el-link slot="trigger" :disabled="isShow" type="primary" class="vg_cursor">粘贴</el-link>
                      </div>
                    </div>
                    <el-date-picker
                      v-if="item.subItem.type === 'date' || item.subItem.type === 'month'"
                      v-model="scope.row[item.prop]"
                      show-word-limit
                      :type="item.subItem.type"
                      :disabled="typeof item.subItem.disabled === 'function' ? item.subItem.disabled(scope.row) : item.subItem.disabled"
                      size="mini"
                      clearable
                      value-format="timestamp"
                      placeholder="请选择"
                    >
                    </el-date-picker>
                    <el-select
                      v-if="item.subItem.type === 'select'"
                      :multiple="item.subItem.multiple"
                      :clearable="item.subItem.clearable"
                      size="mini"
                      :collapse-tags="item.subItem.collapseTags"
                      :multiple-limit="item.subItem.multipleLimit"
                      :name="item.subItem.name"
                      :placeholder="item.subItem.placeholder"
                      :allow-create="item.subItem.allowCreate"
                      :disabled="typeof item.subItem.disabled === 'function' ? item.subItem.disabled(scope.row) : item.subItem.disabled"
                      :filter-method="item.subItem.filterMethod"
                      :filterable="item.subItem.filterable"
                      :loading-text="item.subItem.loadingText"
                      :no-data-text="item.subItem.noDataText"
                      :popper-class="item.subItem.popperClass"
                      :no-match-text="item.subItem.noMatchText"
                      :loading="item.subItem.loading"
                      :remote="item.subItem.remote"
                      :remote-method="item.subItem.remoteMethod"
                      v-model="scope.row[item.prop]"
                      clearable
                      @change="event => (item.subItem.change ? item.subItem.change(event, scope.row) : () => {})"
                      @visible-change="typeof item.subItem.visibleChange === 'function' ? item.subItem.visibleChange($event) : () => {}"
                      @remove-tag="typeof item.subItem.removeTag === 'function' ? item.subItem.removeTag($event) : () => {}"
                    >
                      <el-option
                        v-for="(subOption, index) in item.subItem.options"
                        :key="`${tableRowKey}subSelect${subOption.value}${index}`"
                        :label="subOption.label"
                        :value="subOption.value"
                        :disabled="subOption.disabled"
                        :style="selectOptionStyle"
                      />
                    </el-select>
                  </el-form-item>
                </div>
                <span
                  v-else
                  v-html="
                    item.formatter
                      ? item.needOtherColumn
                        ? item.formatter(scope.row)
                        : item.formatter(scope.row[item.prop])
                      : scope.row[item.prop] || scope.row[item.prop] === 0
                      ? scope.row[item.prop]
                      : '&nbsp;'
                  "
                >
                </span>
              </template>
            </el-table-column>
          </template>
        </el-table>
      </div>
      <!--<el-pagination-->
      <!--  class="searchPagination"-->
      <!--  v-if="needPagination"-->
      <!--  background-->
      <!--  @size-change="sizeChangeHandle"-->
      <!--  @current-change="currentChangeHandle"-->
      <!--  :current-page="currentPage"-->
      <!--  :total="totalPage"-->
      <!--  :page-size="pageSize"-->
      <!--  layout="total, prev, pager, next"-->
      <!--  ref="pubPagination"-->
      <!--&gt;-->
      <!--</el-pagination>-->
      <el-pagination
        v-if="needPagination"
        ref="pubPagination"
        :current-page="currentPage"
        :layout="`total,${needPageSizes ? 'sizes,' : ''} prev, pager, next`"
        :page-size="pageSize"
        :page-sizes="[50, 100, 150, 200]"
        background
        :total="totalPage"
        class="searchPagination"
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
      >
      </el-pagination>
    </el-form>
  </div>
</template>

<script>
import Sortable from 'sortablejs';
import { BigNumber } from 'bignumber.js';
import helper from '@assets/js/helper';
import { imgeAPI } from '@api/modules/imge';

export default {
  name: 'SearchTable',
  components: { Sortable },
  props: {
    form: { type: Object, default: () => {} },
    isShow: { type: Boolean, default: false },
    computedData: { type: Object, default: () => {} },
    columns: { type: Array, default: () => [] },
    stripe: { type: Boolean, default: true },
    showSummary: { type: Boolean, default: false },
    summariesColumns: { type: Array, default: () => [] },
    needSummaryValues: { type: String, default: () => '' },
    pagination: { type: Object, default: () => ({}) },
    data: { type: Array, default: () => [] },
    loadFlag: { type: Boolean, default: false },
    needPagination: { type: Boolean, default: true },
    needPageSizes: { type: Boolean, default: false },
    needSort: { type: Boolean, default: false },
    needSummation: { type: Boolean, default: false },
    needSearch: { type: Boolean, default: true },
    needFixedHeight: { type: Boolean, default: false },
    needExpand: { type: Boolean, default: false },
    rowMove: { type: Boolean, default: false },
    columnMove: { type: Boolean, default: true },
    dbClickJp: { type: Function, default: () => {} },
    rowClick: { type: Function, default: () => {} },
    rowClassName: { type: Function, default: () => {} },
    rowKey: { type: Function || String, default: null },
    rowStyle: { type: Function || Object, default: ({ row, rowIndex }) => (row.index = rowIndex) },
    tableSelect: { type: Function, default: () => null },
    tableSelectionChange: { type: Function, default: () => null },
    tableSelectAll: { type: Function, default: () => null },
    totalPage: { type: Number, default: 0 },
    pageSize: { type: Number, default: 20 },
    getTableData: { type: Function, default: () => null },
    selectable: { type: Function, default: () => true },
    selectOptionStyle: String,
    tableRowKey: String,
    headerCellStyle: {
      type: Function || Object,
      default: () => {
        return {
          'font-size': '14px',
          height: '100px',
          padding: 0
        };
      }
    },
    cellStyle: {
      type: Function || Object,
      default: () => {
        return {
          'font-size': '14px'
        };
      }
    }
  },
  computed: {
    tableData() {
      return this.data ?? [];
    },
    tableColumn() {
      return this.columns ?? [];
    }
  },
  data() {
    return {
      searchForm: {
        page_no: 1
      },
      tableKey: '',
      tableMaxHeight: 0,
      currentPage: 1,
      uploadUrl: helper.modePrepend(imgeAPI.uploadImage),
      dataBody: {
        file: /\.(png|jpg|gif|jpeg)$/,
        cut: true,
        keep: false,
        x: 400,
        y: 400
      }
    };
  },
  created() {
    this.initSearchForm();
  },
  async mounted() {
    await this.rowDrop();
    await this.columnDrop();
    await this.getTableMaxHeight();
  },
  updated() {
    if (this.needSummation) this.$nextTick(() => this.$refs['multiTable'].doLayout());
  },
  methods: {
    initSearchForm() {
      let continueColumn = ['selection', 'index'];
      this.tableColumn.forEach(({ prop }) => {
        if (continueColumn.includes(prop)) return;
        this.$set(this.searchForm, prop, null);
      });
      this.searchForm.page_no = 1;
      this.searchForm.page_size = this.pageSize;
    },
    getTableMaxHeight() {
      if (this.needFixedHeight || this.$route.path.indexOf('list') !== -1) {
        let pageHeight = this.$refs.pubPagination?.$el.clientHeight || 0;
        let e = document.getElementsByClassName(this.tableRowKey)[0];
        this.tableMaxHeight = window.innerHeight - e.getBoundingClientRect().y - e.getBoundingClientRect().height - pageHeight - 60;
        return;
      }
      this.tableMaxHeight = 400;
    },
    positionToRepair() {
      // if (this.needPagination === false) return;
      // if (this.columnMove === false) return;
      // let table = this.$refs.multiTable;
      // let elements = table.$el.querySelectorAll(`.el-table__header-wrapper .caret-wrapper`);
      // for (let i = 0; i < elements.length; i++) {
      //   if (elements[i].parentNode.children[1].children.length > 1) return;
      //   elements[i].parentNode.children[1].appendChild(elements[i]);
      // }
    },
    rowDrop() {
      if (this.rowMove === false) return;
      const tbody = document.querySelector(`.${this.tableRowKey} .el-table__body-wrapper tbody`);
      const _this = this;
      Sortable.create(tbody, {
        animation: 350,
        delay: 100,
        onEnd({ newIndex, oldIndex }) {
          if (oldIndex === newIndex) return;
          const currentRow = _this.tableData.splice(oldIndex, 1)[0];
          _this.tableData.splice(newIndex, 0, currentRow);
          _this.reDrawTable();
        }
      });
    },
    columnDrop() {
      if (this.columnMove === false) return;
      this.$nextTick(() => {
        const _this = this;
        const wrapperTr = document.querySelector(`.${this.tableRowKey} .el-table__header-wrapper tr`);
        Sortable.create(wrapperTr, {
          animation: 350,
          delay: 300,
          invertSwap: false,
          onEnd: evt => {
            if (evt.oldIndex === evt.newIndex) return;
            const oldItem = this.tableColumn[evt.oldIndex];
            _this.tableColumn.splice(evt.oldIndex, 1);
            _this.tableColumn.splice(evt.newIndex, 0, oldItem);
            _this.reDrawTable();
          }
        });
      });
    },
    reDrawTable() {
      // this.tableKey = crypto.randomUUID();
      this.$nextTick(() => {
        this.rowDrop();
        this.columnDrop();
        this.$refs.multiTable.doLayout();
      });
    },
    sortChange({ order, prop }) {
      if (!order) {
        this.searchForm.column = '';
        this.searchForm.order = '';
        return;
      }
      this.searchForm.column = prop;
      this.searchForm.order = order.substring(0, order.indexOf('ending'));
      this.getData();
    },
    handleClick(e) {
      e.cancelBubble = true;
    },
    getData(index) {
      if (this.needPagination) {
        this.currentPage = 1;
        this.searchForm.page_no = 1;
        if (!this.needPageSizes) this.searchForm.page_size = this.pageSize;
      }
      this.$emit('getTableData', index);
    },
    resetFields() {
      this.searchForm = { page_no: 1, page_size: this.searchForm.page_size };
      this.currentPage = 1;
    },
    cellClick(row, column, cell, event) {
      navigator.clipboard.writeText(event.target.innerText);
    },
    sizeChangeHandle(val) {
      this.searchForm.page_size = val;
      this.$emit('getTableData');
    },
    currentChangeHandle(val) {
      this.searchForm.page_no = val;
      this.currentPage = val;
      this.$emit('getTableData');
      this.$nextTick(() => {
        setTimeout(() => (this.$refs.multiTable.bodyWrapper.scrollTop = 0));
      });
    },
    clearSelection() {
      this.$refs.multiTable.clearSelection();
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      let changeIndex = 0;
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        if (column.property === this.needSummaryValues) changeIndex = index;
        let values = data.map(item => Number(item[column.property]));
        if (this.summariesColumns.includes(column.property)) {
          let initValue = new BigNumber(0);
          sums[index] = values
            .reduce((x, item) => {
              return x.plus(item);
            }, initValue)
            .toNumber()
            .toLocaleString();
        }
      });
      if (this.needSummaryValues && changeIndex > 0) this.$emit('getSummaryValues', sums[changeIndex].replace(/,/g, ''));
      return sums;
    },
    // 成功上传
    handleAvatarSuccess(res, row) {
      if (res.code === 0) {
        row.imge_id = res.data.imge_id;
        row.imge_url = res.data.imge_url;
      }
    },
    // 上传头像前
    beforeAvatarUpload(res) {
      const isLt5M = res.size / 1024 / 1024 < 3;
      if (!isLt5M) {
        this.$message.error('图片大小不得超过 3MB!');
        return false;
      }
    },
    handlePaste(event, index) {
      if (!this.isShow) {
        const items = (event.clipboardData || window.clipboardData).items;
        let file = null;
        if (!items || items.length === 0) {
          this.$message.error('当前浏览器不支持本地');
          return;
        }
        // 搜索剪切板items
        for (let i = 0; i < items.length; i++) {
          if (items[i].type.indexOf('image') !== -1) {
            file = items[i].getAsFile();
            break;
          }
        }
        if (!file) {
          this.$message.error('粘贴内容非图片');
          return;
        }
        this.$refs[`uploadMultiple${index}`][0].handleStart(file); // 将粘贴过来的图片加入预上传队列
        this.$refs[`uploadMultiple${index}`][0].submit(); // 提交图片上传队列
      } else {
        this.$message.warning('请先编辑');
      }
    },
    headerRemoveTagChange(val, selectedValue) {
      if (selectedValue.length === 0) this.getData();
    }
  }
};
</script>
<style scoped>
.headerText {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
</style>
<style scoped>
::v-deep .headerCellClass {
  font-size: 14px;
  height: 100px !important;
  padding: 0;
}
::v-deep .headerCellClass > .cell {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
::v-deep .el-table--border .el-table__cell:first-child .cell {
  padding-left: 0;
}
::v-deep .el-upload {
  display: inline;
  text-align: center;
  cursor: pointer;
  outline: 0;
}
::v-deep .el-upload-dragger {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 80px;
  background-color: #e6f7ff;
  border: 0;
}
::v-deep .upload-demo {
  display: inline;
}
::v-deep .el-upload--text {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f5f500;
  border: 0;
  width: 80px;
  height: 80px;
}
.summation {
  height: 20px;
  text-align: right;
  padding-right: 10px;
}
.summationBorderBottom {
  border-bottom: 1px solid #494a4d;
}
.searchPagination {
  display: flex;
  justify-content: flex-end;
}
</style>
<style>
.changeColor {
  background: #f8e9db !important;
}
</style>
